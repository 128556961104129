<template>
  <div class="in-workbench">
    <div class="left">
    <el-button v-if="buttonflag" type="primary" @click="dialog" style="text-align: left">上传文件</el-button>
    <el-dialog title="文件上传窗口" :visible.sync="UpDateUser" :append-to-body="true" width="50%" top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
      <el-form>
        <el-row>
          <el-col :xs=24 :sm=12 style="text-align: center">
            <el-upload style="margin-left: 50%"
                       class="upload-demo"
                       drag
                       :action=UploadUrl
                       :data = Uploaddata
                       :on-success="handleAvatarSuccess"
                       :file-list="fileList"
                       :auto-upload="true"
                       multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">

        <el-button type="danger" @click="back">上传完成</el-button>
      </span>
    </el-dialog>
  </div>
    <div class="blank20"></div>
    <template>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          label="上传时间"
          width="300">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.gmtCreate | dateFormater }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="文件名"
          width="400" prop="name">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tag type="success"><a :href="'https://' + scope.row.url" target="_blank">下载</a></el-tag>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="block">
      <span class="demonstration"></span>
      <el-pagination
        @size-change="handleSizeChange"
        :current-page.sync="pages"  @current-change="handleCurrentChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="pagetotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import clientUtils from "../../js/clientUtils";
  import enums from "../../js/enums";

  export default {
        name: "Tes",
    data: function () {
      return {
        Uploaddata:{},
        pages: 0,
        fillpage: 1,
        pageflag: true,
        pagetotal: '',
        fileList: [],
        Hyperlinks: '',
        DownloadUrl: null,
        UpDateUser: false,
        fileUrl:'',
        categoryName :'',
        buttonflag:false,
        options:[{id:1,name:"易损品"},{id:2,name:"消耗品"}],
        tableData: [],
        UploadUrl:"http://39.103.237.127:8160/system/file/upload/oss/"+clientUtils.getLocalUserInfo().id,
      }
    },
    filters: {
      dateFormater: function (datetime) {
        return clientUtils.formatDatetime(datetime, enums.datetimeFormat.FULL_CHS)
      }
    },

    methods: {
        dialog() {
          this.UpDateUser = true
        },
        back() {
          this.UpDateUser = false
          this.fileList = []
        },
      handleAvatarSuccess(res, file) {
        console.log(JSON.stringify(res));
        console.log(JSON.stringify(file));
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        const that = this;
        console.log(this.fileUrl);
        clientUtils.get(this.fileUrl,function (res) {
          if (res.data!=null){
            that.pagetotal =parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            console.log(that.pagetotal);
            console.log(that.tableData)
          }
        })

      },
        page() {

        },
        handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
          console.log(val);
          this.fillpage=`${val}`;
          console.log('页码'+this.fillpage);
          this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
          this.pageflag =false;
          const that = this;
          console.log(this.fileUrl);
          clientUtils.get(this.fileUrl,function (res) {
            if (res.data!=null){
              // that.pagetotal = res.data.data.total;
              that.tableData = res.data.data.records;
              console.log(that.tableData)
            }
          })
        },
      },
      mounted() {
        const category = this.$route.params.category;
        console.log('用户名'+clientUtils.getLocalUserInfo().username);
        console.log('这是' + category);
        switch (category) {
          case 'contingency':
           this.categoryName = '应急预案';
            this.$emit('selectMenu', this.$route.path, '应急预案');
            this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
            console.log('路径' + this.$route.path);
            console.log(this.fileUrl)
            break;
          case 'archives':
            this.categoryName = '知识培训';
            this.$emit('selectMenu', this.$route.path, '知识培训');
            console.log('路径' + this.$route.path);
            this.fileUrl='/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
            console.log(this.fileUrl)
            break;
          case 'mouthPlan':
            this.categoryName = '月计划';
            this.Uploaddata.uploadKey='月计划';
            this.$emit('selectMenu', this.$route.path, '月计划');
            this.fileUrl = '/system/ihsFile/selectPage/monthPlan?current='+ this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id;
            console.log('路径' + this.$route.path);
            console.log(this.fileUrl)
            break;
          case 'weekPlan':
            this.categoryName = '周计划';
            this.$emit('selectMenu', this.$route.path, '周计划');
            this.Uploaddata.uploadKey='周计划';
            this.fileUrl = '/system/ihsFile/selectPage/weekPlan?current='+ this.fillpage + '&size=10';
            console.log('路径' + this.$route.path);
            console.log(this.fileUrl)
            break;

          case 'personalFiles':
            this.categoryName = '我的文件记录';
            this.$emit('selectMenu', this.$route.path, '我的文件记录');
            this.fileUrl ='/system/ihsFile/selectPage/personalFiles?current='+ this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id;
            console.log('路径' + this.$route.path);
            break;
          default:
            this.categoryName = ''
        }
        if (this.categoryName === '') {
          this.$router.push('/default')
        } else {
          // 参数正确
          this.category = category
        }
        if (category == 'contingency'||category == 'archives'||category=='mouthPlan'||category =='weekPlan'||category == 'personalFiles' ) {
          if (this.pageflag) {
            const that = this;
            clientUtils.get(this.fileUrl, function (res) {
              if (res.data != null) {
                that.pagetotal = parseInt(res.data.data.total);
                that.tableData = res.data.data.records
              }
            })
          }
        }
        if (this.categoryName == "周计划"||this.categoryName == "月计划"){
          this.buttonflag =true
        }

      }
  }
</script>

<style scoped>
.left{
  text-align: left;
}

</style>
